<template>
  <div>
    <ValidationObserver ref="building" v-slot="{ handleSubmit }">
      <div class="subject">
        <div class="text-container">
          <section>
            <div tabindex="0">
              <button
                class="tab"
                :class="{ focus: tabs === 'zh' }"
                @click.prevent="tabs = 'zh'"
              >
                中文
              </button>
            </div>
            <div tabindex="0">
              <button
                class="tab"
                :class="{ focus: tabs === 'en' }"
                @click.prevent="tabs = 'en'"
              >
                英文
              </button>
            </div>
          </section>

          <div class="subject-title">
            <div style="display:flex;">
              <input
                style="flex:1;"
                class="underline input-style"
                placeholder="標題"
                v-model="FieldName_zhTW"
                :disabled="!isEdit"
                v-show="tabs === 'zh'"
              />
              <input
                style="flex:1;"
                class="underline input-style"
                placeholder="Title"
                v-model="FieldName_enUS"
                :disabled="!isEdit"
                v-show="tabs === 'en'"
              />

              <div class="icon-tools">
                <eva-icon
                  fill="#5B5B5B"
                  class="icon"
                  name="edit-outline"
                  v-if="!isEdit"
                  @click="isEdit = true"
                />
                <eva-icon
                  fill="#5B5B5B"
                  class="icon"
                  name="trash-2-outline"
                  @click="del"
                />
              </div>
              
            </div>

            <ValidationProvider
              class="option"
              rules="required"
              v-slot="{ errors }"
              name="中文標題"
            >
              <input
                class="underline input-style"
                placeholder="標題"
                v-model="FieldName_zhTW"
                :disabled="!isEdit"
                v-show="false"
              />
              <span v-if="errors[0]" class="text-danger">
                中文標題{{ errors[0] }} 
              </span>
            </ValidationProvider>

            <ValidationProvider
              class="option"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                class="underline input-style"
                placeholder="Title"
                v-model="FieldName_enUS"
                :disabled="!isEdit"
                v-show="false"
              />
              <span v-if="errors[0]" class="text-danger">
                英文標題{{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <input
        v-if="hasDescription"
        class="underline description-style"
        placeholder="備註說明"
        v-model="description"
        :disabled="!isEdit"
      />
      <div v-if="isEdit" class="tag-group">
        <!-- <span
          class="badge tag"
          :style="[
            isRequired ? { background: '#3DBA90' } : { background: '#BDBDBD' },
          ]"
          @click="isRequired = !isRequired"
        >
          <eva-icon fill="#fff" name="star-outline" class="icon" />
          <span>必填</span>
        </span> -->
        <!-- <span
          class="badge tag"
          :style="[
            hasDescription
              ? { background: '#3DBA90' }
              : { background: '#BDBDBD' },
          ]"
          @click="hasDescription = !hasDescription"
        >
          <eva-icon fill="#fff" name="message-square-outline" class="icon" />
          <span>備註</span>
        </span> -->
      </div>
      <div class="answer">
        <!-- <input class="basic style" disabled /> -->
      </div>
      <div v-if="isEdit" class="button-group">
        <button type="button" class="btn btn-light btn-sm" @click="cancel">
          取消
        </button>
        <button type="button" class="btn btn-info btn-sm" @click="handleSubmit(done)">
          完成
        </button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["data"],
  name: "Remark",

  data() {
    return {
      isEdit: true,
      // title: null,
      FieldName_zhTW: null,
      FieldName_enUS: null,
      isRequired: false,
      hasDescription: false,
      description: null,
      Guid: null,
      Finished: false,

      tabs: 'zh'
    };
  },
  watch: {
    isEdit: function() {
      if(this.isEdit) {
        this.Finished = false

        const data = {
          Guid: this.data.Guid,
          id: this.data.id,
          FieldType: "Remark",
          FieldName_zhTW: this.FieldName_zhTW,
          FieldName_enUS: this.FieldName_enUS,
          Required: this.isRequired,
          Finished: !this.isEdit,
        };
        this.Finished = !this.isEdit
        this.$emit("addItem", data);
      } else {
        this.Finished = true
      }
    },
    data: function() {
      if(this.Finished) {
        this.Guid = this.data.Guid
        this.FieldName_zhTW = this.data.FieldName_zhTW
        this.FieldName_enUS = this.data.FieldName_enUS
        this.isRequired = this.data.Required
      }
      
    }
  },
  methods: {
    // 刪除
    del() {
      this.$emit("delItem", this.data);
    },

    // 完成
    done() {
      this.isEdit = false;
      const data = {
        Guid: this.data.Guid,
        id: this.data.id,
        FieldType: "Remark",
        FieldName_zhTW: this.FieldName_zhTW,
        FieldName_enUS: this.FieldName_enUS,
        // name: this.data.name,
        // title: this.title,
        Required: this.isRequired,
        Finished: !this.isEdit,
        // description: this.hasDescription ? this.description : null,
      };
      
      this.Finished = !this.isEdit
      this.$emit("addItem", data);
    },

    // 取消
    cancel() {
      if(this.Guid === null || (this.FieldName_zhTW === undefined && this.FieldName_enUS === undefined)) {
        this.$emit("delItem", this.data);
      } else {
        this.isEdit = false
      }
    },
  },
  created() {
    if(this.data.status === 'edit' && this.data.Guid !== "00000000-0000-0000-0000-000000000000") {
      this.Guid = this.data.Guid
      this.FieldName_zhTW = this.data.FieldName_zhTW
      this.FieldName_enUS = this.data.FieldName_enUS
      this.isRequired = this.data.Required
      // this.options = this.data.Option

      this.isEdit = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_tabs.scss';

.subject {
  display: flex;
  position: relative;
  margin-bottom: 10px;

  align-items: flex-end;

  .subject-title {
    width: 100%;
    input:focus {
      border-bottom: 2px solid #000;
    }
    .input-style {
      display: block;
      width: 100%;
      height: 30px;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      box-shadow: none;
      box-sizing: border-box;
    }
  }
  .subject-tools {
    display: block;
    /deep/ svg {
      width: 20px;
      height: 20px;
    }
  }
  .subject-tools div {
    display: flex;
    flex: 1 1 15%;
    height: 30px;
    border-bottom: 1px solid #d2d2d2;
    .icon {
      flex: 1 1;
      cursor: pointer;
      padding: 2px 5px;
    }
  }

  .text-container {
    flex: 1;
  }
}
.description-style {
  display: block;
  width: 100%;
  height: 30px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.tag-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .tag {
    cursor: pointer;
    color: #fff;
    background: #bdbdbd;
    margin-right: 5px;
    padding: 4px 6px;
    span {
      margin-left: 3px;
      vertical-align: middle;
    }
  }
  /deep/ svg {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
}
.answer {
  display: block;
  margin-bottom: 10px;
  .basic {
    width: 100%;
    color: #454545;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    background-color: #fff;
  }
}
.button-group {
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 6px;
  }
}

.underline {
  color: #454545;
  border: none;
  border-bottom: 1px solid #d2d2d2;
}

input {
  outline: none;
}

input:focus {
  border-bottom: 2px solid #000;
}
</style>
