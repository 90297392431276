var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"building",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"subject"},[_c('div',{staticClass:"text-container"},[_c('section',[_c('div',{attrs:{"tabindex":"0"}},[_c('button',{staticClass:"tab",class:{ focus: _vm.tabs === 'zh' },on:{"click":function($event){$event.preventDefault();_vm.tabs = 'zh'}}},[_vm._v(" 中文 ")])]),_c('div',{attrs:{"tabindex":"0"}},[_c('button',{staticClass:"tab",class:{ focus: _vm.tabs === 'en' },on:{"click":function($event){$event.preventDefault();_vm.tabs = 'en'}}},[_vm._v(" 英文 ")])])]),_c('div',{staticClass:"subject-title"},[_c('div',{staticStyle:{"display":"flex"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.FieldName_zhTW),expression:"FieldName_zhTW"},{name:"show",rawName:"v-show",value:(_vm.tabs === 'zh'),expression:"tabs === 'zh'"}],staticClass:"underline input-style",staticStyle:{"flex":"1"},attrs:{"placeholder":"標題","disabled":!_vm.isEdit},domProps:{"value":(_vm.FieldName_zhTW)},on:{"input":function($event){if($event.target.composing){ return; }_vm.FieldName_zhTW=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.FieldName_enUS),expression:"FieldName_enUS"},{name:"show",rawName:"v-show",value:(_vm.tabs === 'en'),expression:"tabs === 'en'"}],staticClass:"underline input-style",staticStyle:{"flex":"1"},attrs:{"placeholder":"Title","disabled":!_vm.isEdit},domProps:{"value":(_vm.FieldName_enUS)},on:{"input":function($event){if($event.target.composing){ return; }_vm.FieldName_enUS=$event.target.value}}}),_c('div',{staticClass:"icon-tools"},[(!_vm.isEdit)?_c('eva-icon',{staticClass:"icon",attrs:{"fill":"#5B5B5B","name":"edit-outline"},on:{"click":function($event){_vm.isEdit = true}}}):_vm._e(),_c('eva-icon',{staticClass:"icon",attrs:{"fill":"#5B5B5B","name":"trash-2-outline"},on:{"click":_vm.del}})],1)]),_c('ValidationProvider',{staticClass:"option",attrs:{"rules":"required","name":"中文標題"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.FieldName_zhTW),expression:"FieldName_zhTW"},{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"underline input-style",attrs:{"placeholder":"標題","disabled":!_vm.isEdit},domProps:{"value":(_vm.FieldName_zhTW)},on:{"input":function($event){if($event.target.composing){ return; }_vm.FieldName_zhTW=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" 中文標題"+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"option",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.FieldName_enUS),expression:"FieldName_enUS"},{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"underline input-style",attrs:{"placeholder":"Title","disabled":!_vm.isEdit},domProps:{"value":(_vm.FieldName_enUS)},on:{"input":function($event){if($event.target.composing){ return; }_vm.FieldName_enUS=$event.target.value}}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" 英文標題"+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),(_vm.hasDescription)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"underline description-style",attrs:{"placeholder":"備註說明","disabled":!_vm.isEdit},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"tag-group"},[_c('span',{staticClass:"badge tag",style:([
          _vm.isRequired ? { background: '#3DBA90' } : { background: '#BDBDBD' } ]),on:{"click":function($event){_vm.isRequired = !_vm.isRequired}}},[_c('eva-icon',{staticClass:"icon",attrs:{"fill":"#fff","name":"star-outline"}}),_c('span',[_vm._v("必填")])],1)]):_vm._e(),_c('div',{staticClass:"answer"},[_c('input',{staticClass:"basic style",attrs:{"disabled":""}})]),(_vm.isEdit)?_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"btn btn-light btn-sm",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('button',{staticClass:"btn btn-info btn-sm",attrs:{"type":"button"},on:{"click":function($event){return handleSubmit(_vm.done)}}},[_vm._v(" 完成 ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }